<template>
  <v-menu
    v-model="datePicker"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-if="!readonly"
        v-model="date"
        dense
        outlined
        :label="label"
        :placeholder="label"
        :prepend-inner-icon="icons.mdiCalendar"
        hide-details="auto"
        readonly
        v-bind="attrs"
        :rules="rules"
        :clearable="clearAble"
        :class="inputClass"
        v-on="on"
      ></v-text-field>
      <v-text-field
        v-else
        v-model="date"
        dense
        outlined
        :label="label"
        :placeholder="label"
        :prepend-inner-icon="icons.mdiCalendar"
        hide-details="auto"
        readonly
        v-bind="attrs"
        :rules="rules"
        :clearable="clearAble"
        :class="inputClass"
        disabled
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      color="primary"
      :min="minDate"
      :max="maxDate"
      @input="datePicker = false"
    ></v-date-picker>
  </v-menu>
</template>
<script>
import { required } from '@core/utils/validation'
import { mdiCalendar } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useVModel } from '@vueuse/core'

export default {
  props: {
    value: { type: String, default: '' },
    label: { type: String, default: '' },
    rules: { type: Array, default: () => [] },
    clearAble: { type: Boolean, default: false },
    inputClass: { type: String, default: '' },
    minDate: { type: String, default: '' },
    maxDate: { type: String, default: '' },
    readonly: { type: Boolean, default: false },
  },
  setup(props, { emit }) {
    const datePicker = ref(false)
    const date = useVModel(props, 'value', emit, { eventName: 'input' })

    return {
      datePicker,
      date,
      icons: {
        mdiCalendar,
      },
      validators: {
        required,
      },
    }
  },
  mounted() {
    // console.log(this.$attrs)
  },
}
</script>
